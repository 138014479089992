import { Container, VStack, Text, Link } from '@chakra-ui/core';
import React, { useState } from 'react';
import AddEntryBar from './addEntryBar';
import Entry from './entry/entry.js';
import LoginStripe from './loginStripe.jsx';
import Spinner from './spinner/Spinner';

const EntriesList = (props) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const collection = props.collection;
  const excludeCollection = 'blockchain';

  return (
    <>
      <LoginStripe setPosts={setPosts} setIsLoading={setIsLoading} collection={collection}></LoginStripe>
      <AddEntryBar collection={collection} />

      <Container maxW="lg.100" centerContent p={2} mb="4em">
        {isLoading ? (
          <Spinner />
        ) : (
          <VStack spacing={4} w="100%">
            {posts.map((entry) => (
              <Entry entry={entry} key={entry.id} collection={collection} />
            ))}
          </VStack>
        )}

        {collection !== excludeCollection ?? (
          <Text mt="3em">
            This dictionary is part of the <Link href="https://www.solidcode.eu">solidCode.eu</Link> IT training. It has been created for FREE usage.
            &copy; solidCode Ltd.
          </Text>
        )}
      </Container>
    </>
  );
};

export default EntriesList;
