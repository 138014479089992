import React from "react";
import { Button, Image, Flex, Text, Tooltip, Center } from "@chakra-ui/core";
import { FaGithub, FaFacebook, FaGoogle, FaMicrosoft } from "react-icons/fa";

const GuestGreeting = (props) => {

  return (
    <>

  <Flex justifyContent="flex-start" w="100%" top={0} verticalAlign="" >
  <Image src="./img/dodi-thin.png" htmlWidth={40}></Image>
  <Center>
  <Text marginLeft="1em"> Login with </Text>
  <Button variant="solid"  
          mr="1em"
          border="1px"
          marginLeft="1em"
          fontWeight="bold"
          size="sm" 
          float="left"
          onClick={ props.logIn } 
          leftIcon={ < FaGoogle /> }
          >  Google
      </Button>

    <Tooltip label="Login to view the FREE IT dictionary ">
      <Button variant="solid" 
          mr="1em"
          border="1px"
          marginLeft="1em"
          fontWeight="bold"
          size="sm" 
          float="right"
          onClick={ props.logIn } 
          leftIcon={ <FaGithub /> }
            > GitHub
      </Button>
    </Tooltip>

      <Button variant="solid"  
          mr="1em"
          border="1px"
          marginLeft="1em"
          fontWeight="bold"
          size="sm" 
          float="left"
          onClick={ props.logIn } 
          leftIcon={ <FaFacebook /> }
          >  Facebook
      </Button>

      <Button variant="solid"  
          mr="1em"
          border="1px"
          marginLeft="1em"
          fontWeight="bold"
          size="sm" 
          float="left"
          onClick={ props.logIn } 
          leftIcon={ < FaMicrosoft /> }
          >  Microsoft
      </Button>

    <p> to view the <span color="teal.500">FREE</span> IT dictionary </p>
  </Center>
  </Flex>
  </>

  );
};

export default GuestGreeting;