import React, { useEffect, useState } from "react";
import LoginHandler from "./user/login-handler.js";
import { Container, HStack, useToast } from "@chakra-ui/core";
import fs from "../lib/firebase-helper";
import log from "../lib/logDev";

const LoginStripe = ({ setPosts, setIsLoading, collection }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toast = useToast();
  const _setIsLoading = setIsLoading;
  const _setPosts = setPosts;
  const _collection = collection;

  let user = null;
  try {
    user = JSON.parse(sessionStorage.getItem("userData"));
  } catch (error) {
    setIsLoggedIn(false);
    console.log(error);
  }

  useEffect(
    (user) => {
      console.log("useEffect called");

      if (user && user.isLoggedIn) {
        console.log("User logged in already");
        console.log(user);
        setIsLoggedIn(true);
        showCopyrightNote(toast);
      } else {
        setIsLoggedIn(false);
        showCockieNote(toast);
        console.log("user not logged in");
        console.log(user);
      }

      getEntries(_setPosts, _setIsLoading, collection);
      // Hook to handle the real-time updating of posts whenever there is a
      // change in the datastore (https://firebase.google.com/docs/firestore/query-data/listen#view_changes_between_snapshots)
    },
    [_setIsLoading, _setPosts, collection, setIsLoading, setPosts, toast]
  );

  return (
    <>
      <Container maxW="lg.100" p={2}>
        <HStack spacing={2} w="100%">
          <LoginHandler
            m="0"
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            getEntries={getEntries}
            setPosts={setPosts}
            clearEntries={clearEntries}
            user={user}
            setIsLoading={_setIsLoading}
            collection={_collection}
          />
        </HStack>
      </Container>
      ;
    </>
  );
};

function getEntries(setPosts, setLoading, collection) {
  log("getEntries called with collection: " + collection);
  setLoading(true);

  const collName =
    collection !== undefined
      ? collection
      : process.env.REACT_APP_FIREBASE_COLLECTION;

  log("Calling firebase with collection: " + collName);

  fs.collection(collName)
    .orderBy("expression", "asc")
    .onSnapshot((querySnapshot) => {
      const _posts = [];

      querySnapshot.forEach((doc) => {
        _posts.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setPosts(_posts);
      setLoading(false);
    });
}

function clearEntries(setPosts) {
  setPosts([]);
}

function showCockieNote(localToast) {
  try {
    localToast({
      title: "Dear guest!",
      description:
        "This site is using cookies for basic functionality only. By using this site you accept cookie usage." +
        "By logging in to this site you consent to storing your email address, for the purposes of identifying you as a user " +
        "and sending you IT related information.",
      status: "warning",
      position: "bottom-right",
      duration: 30000,
      isClosable: true,
    });
  } catch (error) {
    console.log(error.message);
  }
}

function showCopyrightNote(localToast) {
  try {
    localToast({
      title: "",
      description: "solidCode IT Training",
      status: "info",
      position: "bottom-right",
      duration: 30000,
      isClosable: false,
      outerWidth: "100%",
      variant: "transparent",
    });
  } catch (error) {
    console.log(error.message);
  }
}

export default LoginStripe;
