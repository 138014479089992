import { Box } from '@chakra-ui/core';
import LikeButton from './like-button';

import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Tag, TagLeftIcon } from '@chakra-ui/react';
import { FiTag } from 'react-icons/fi';

const Entry = ({ entry, collection }) => {
  /*
    const [context, setContext] = useState("");
    const [definition, setDefinition] = useState("");
    const [expression, setExpression] = useState("");
  
    const handleExpression = async ( entry, value ) => {   
    
        entry.expression = value;       
        await setEntry(entry); 
      }

      const handleContext = async ( entry, value ) => {   
    
        entry.context = value;       
        await setEntry(entry); 
      }

      const handleDefinition = async ( entry, value ) => {   
    
        entry.definition = value;
        await setEntry(entry); 
      }
    
      async function setEntry(entry) {
        const date = new Date();
     
        await fs.collection(process.env.REACT_APP_FIREBASE_COLLECTION).doc(entry.id).set({
            context: entry.context,
            expression: entry.expression,
            definition: entry.definition,
            likeCount: entry.likeCount,
            createdAt: entry.createdAt,
            updatedAt: date.toUTCString()
        });
    }
    
  */
  return (
    <Accordion textAlign="left" w="92%" defaultIndex={[1]} allowMultiple>
      <AccordionItem isFocusable rounded="lg">
        <Box boxShadow="lg">
          <AccordionButton borderRadius="5" alignItems="flex" bg="#EDF2F6" color="grey" rounded="lg" _hover={{ bg: '#CEDBE8' }}>
            <LikeButton entry={entry} collection={collection} />
            <Box textAlign="left" pl=".5em" pr="1.3em" display="inline-block" minWidth="13em">
              {entry.expression}
            </Box>

            <Tag size="sm" rounded="lg" key={entry.context} w="100%">
              <TagLeftIcon as={FiTag} />
              <Box>{entry.context}</Box>
            </Tag>

            <AccordionIcon alignSelf="right" />
          </AccordionButton>

          <AccordionPanel p="1em 2em 1em 2em" bg="#F3F4FA" borderRadius="5">
            {entry.definition}
          </AccordionPanel>
        </Box>
      </AccordionItem>
    </Accordion>
  );
};

export default Entry;
