import React from 'react';
import log from './lib/logDev';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import EntriesList from './components/entriesList';
import { useParams } from 'react-router-dom';

const App = () => {
  log('App called');

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/itfoundation" element={<EntriesList collection="itfoundation" />} />
          <Route path="/entries" element={<EntriesList collection="entries" />} />
          <Route path="/blockchain" element={<EntriesList collection="blockchain" />} />
          <Route path="/posts" element={<EntriesList collection="posts" />} />

          <Route path="*" element={<EntriesList />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
