import React from "react";
import { Text, Button, Avatar, Image, Flex, Link, Icon, HStack } from "@chakra-ui/core";
import { FiLogOut, FiExternalLink } from "react-icons/fi";

const UserGreeting = (props) => {

  return (
    <>
      <Image src="./img/dodi-thin.png" htmlWidth={40}></Image>
      

      <HStack w="100%">
        <Text>This dictionary is part of the&nbsp; 
          <Link color="orange.500" href="https://www.solidcode.eu/it-training-registration/">solidCode.eu <Icon as={FiExternalLink} /> </Link> 
          &nbsp; IT training. It has been created for&nbsp; <span color="green.500">FREE</span> &nbsp;usage. 
        </Text>
      </HStack>
      <Flex justifyContent="flex-end" w="100%" top={0}>
        
        <Text  mr="1em" fontSize="xs" >{props.userName}</Text>
        <Avatar mr="2em" size="xs" name={props.userName} src={props.photoUrl} />
        
        <Button variant="outline" 
        mr="1em"
        rightIcon={ <FiLogOut/> } 
        border="1px"
        fontWeight="bold"
        size="xs" 
        float="right"
        onClick={props.logOut} 
        colorScheme="red"> LogOut </Button>

      </Flex>
      
</>
  );
};

export default UserGreeting;