import { Box, Container, Flex } from '@chakra-ui/core';
import React from 'react';
import AddNewEntry from './entry/add-new-entry';

const AddEntryBar = ({ collection }) => {
  return (
    <Box top={0} p={4} bg="gray.100" zIndex={1}>
      <Container width="100%" mx="0" p="0" maxW="100%">
        <Flex justifyContent="flex-end" w="100%" top={0}>
          <AddNewEntry collection={collection} />
        </Flex>
      </Container>
    </Box>
  );
};

export default AddEntryBar;
