import { IconButton, Text, VStack } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { FiThumbsUp } from 'react-icons/fi';
import fs from '../../lib/firebase-helper';

const LikeButton = ({ entry, collection }) => {
  const [isVoting, setVoting] = useState(false);
  const [votedPosts, setVotedPosts] = useState([]);

  useEffect(() => {
    const votesFromLocalStorage = localStorage.getItem('votes') || [];
    let previousVotes = [];

    try {
      previousVotes = JSON.parse(votesFromLocalStorage);
    } catch (error) {
      console.error(error);
    }

    setVotedPosts(previousVotes);
  }, []);

  const handleDisablingOfVoting = (postId) => {
    const previousVotes = votedPosts;
    previousVotes.push(postId);
    setVotedPosts(previousVotes);
    localStorage.setItem('votes', JSON.stringify(votedPosts));
  };

  const likeClick = async (e) => {
    setVoting(true);

    let likeCount = entry.likeCount;
    const date = new Date();
    likeCount = likeCount + 1;

    await fs.collection(collection).doc(entry.id).set({
      context: entry.context,
      expression: entry.expression,
      definition: entry.definition,
      likeCount: likeCount,
      createdAt: entry.createdAt,
      updatedAt: date.toUTCString(),
    });

    handleDisablingOfVoting(entry.id);
    setVoting(false);
  };

  const checkIfPostIsAlreadyVoted = () => {
    if (votedPosts.indexOf(entry.id) > -1) {
      return false; // TODO
    } else {
      return false;
    }
  };

  return (
    <>
      <VStack>
        <IconButton
          size="xs"
          colorScheme="green"
          aria-label="ThumbsUp"
          icon={<FiThumbsUp />}
          onClick={(e) => {
            likeClick(e);
            e.stopPropagation();
          }}
          isLoading={isVoting}
          isDisabled={checkIfPostIsAlreadyVoted()}
        />
        <Text bg="gray.80" fontSize="10px" textAlign="center" m={0} p={0}>
          {entry.likeCount}
        </Text>
      </VStack>
    </>
  );
};

export default LikeButton;
