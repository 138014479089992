import UserGreeting from "./user-greating";
import GuestGreeting from "./guest-greeting";
import userData from "../../lib/userData";
import firebase from "firebase";
import log from "../../lib/logDev";

const LoginHandler = (props) => {
  let getEntriesHandler = props.getEntries;
  let setPostsHandler = props.setPosts;
  let clearEntriesHandler = props.clearEntries;
  let setIsLoggedInHandler = props.setIsLoggedIn;
  let isLoggedIn = props.isLoggedIn;
  let user = props.user;
  let setIsLoadingHandler = props.setIsLoading;
  let collectionName = props.collection;

  function getProvider(provider) {
    switch (provider) {
      case "google":
        return new firebase.auth.GoogleAuthProvider();

      case "facebook":
        return new firebase.auth.FacebookAuthProvider();

      case "github":
        return new firebase.auth.GithubAuthProvider();

      case "microsoft":
        return new firebase.auth.OAuthProvider("microsoft.com");

      case "linkedin":
        return new firebase.auth.OAuthProvider("linkedin.com");

      case "apple":
        return new firebase.auth.OAuthProvider("apple.com");

      default:
        console.warn("No login provider");
    }
  }

  function logOut() {
    console.log("logout called");
    setIsLoggedInHandler(false);
    clearEntriesHandler(setPostsHandler);
    sessionStorage.removeItem("userData");
  }

  function logIn(e) {
    var loginType = getLoginType();

    log("logIn called " + loginType);
    var provider = getProvider(loginType); // new fi.rebase.auth.GoogleAuthProvider(); //

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        log("logIn result arrived");

        userData.displayName = result.user.displayName;
        userData.isLoggedIn = true;
        userData.photoUrl = result.user.photoURL;

        setIsLoggedInHandler(true);
        sessionStorage.setItem("userData", JSON.stringify(userData));

        log("Logged in " + userData.displayName);
        getEntriesHandler(setPostsHandler, setIsLoadingHandler, collectionName);
      })
      .catch(function (error) {
        console.log("logIn error");
        console.log(error.message);
        logOut();
      });

    function getLoginType() {
      try {
        return e.target.innerText.toLowerCase();
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  if (isLoggedIn) {
    let userName = user?.displayName;
    let photoUrl = user?.photoUrl;

    if (!userName) userName = userData?.displayName;

    if (!photoUrl) photoUrl = userData?.photoUrl;

    return (
      <UserGreeting logOut={logOut} userName={userName} photoUrl={photoUrl} />
    );
  } else return <GuestGreeting logIn={logIn} />;
};

export default LoginHandler;
