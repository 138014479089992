import { Button, IconButton, InputGroup, InputRightElement, useToast, HStack, Input } from '@chakra-ui/core';
import fs from '../../lib/firebase-helper';
import React, { useState } from 'react';
import { FiDelete } from 'react-icons/fi';

let expressionInput, definitionInput, contextInput;
let expression, definition, context;

const AddNewEntry = ({ collection }) => {
  const [isDisabled, setDisabled] = useState(true);
  const toast = useToast();

  const handleExpression = async (e) => {
    expression = e.target.value;
    if (!expressionInput) expressionInput = e.target;
    setDisabled(isMissingContent(e.target.value, context, definition));
  };

  const handleContext = async (e) => {
    context = e.target.value;
    if (!contextInput) contextInput = e.target;
    setDisabled(isMissingContent(expression, e.target.value, definition));
  };

  const handleDefinition = async (e) => {
    definition = e.target.value;
    if (!definitionInput) definitionInput = e.target;
    setDisabled(isMissingContent(expression, context, e.target.value));
  };

  async function addEntry() {
    const date = new Date().toUTCString();

    await fs.collection(collection).add({
      context: context,
      expression: expression,
      definition: definition,
      likeCount: 0,
      createdAt: date,
      updatedAt: date,
    });

    toast({
      title: "'" + expression + "' has been added to " + collection + ' collection.',
      description: 'Your definition has been added to a list that is sorted in alphabetical order.',
      status: 'success',
      postiotion: 'right',
      duration: 4000,
      isClosable: true,
    });

    expression = '';
    context = '';
    definition = '';

    if (definitionInput) definitionInput.value = '';
    if (expressionInput) expressionInput.value = '';
    if (contextInput) contextInput.value = '';
  }

  function clearEntry(e) {
    expression = '';
    context = '';
    definition = '';

    if (definitionInput) definitionInput.value = '';
    if (expressionInput) expressionInput.value = '';
    if (contextInput) contextInput.value = '';

    setDisabled(true);
  }

  function clearExpression(e) {
    expression = '';
    if (expressionInput) expressionInput.value = '';

    setDisabled(true);
  }

  function clearContext(e) {
    context = '';
    if (contextInput) contextInput.value = '';

    setDisabled(true);
  }

  function clearDefinition(e) {
    definition = '';
    if (definitionInput) definitionInput.value = '';

    setDisabled(true);
  }

  function isMissingContent(expr, cont, def) {
    if (!expr || !cont || !def) return true;

    var ret = !expr.trim().length > 0 || !cont.trim().length > 0 || !def.trim().length > 0;
    return ret;
  }

  return (
    <>
      <HStack w="100%" h="100%" alignItems="flex-start">
        <IconButton
          size="xs"
          colorScheme="yellow"
          mt="2"
          textAlign="left"
          aria-label="Clear all"
          variant="outline"
          icon={<FiDelete />}
          onClick={clearEntry.bind(this)}
        />

        <InputGroup w="37%">
          <Input
            placeholder="Expression"
            defaultValue={expression}
            onChange={handleExpression.bind(this)}
            bg="white.100"
            variant="outline"
            rounded="md"
          />
          <InputRightElement
            children={<IconButton variant="outline" tabIndex="-1" icon={<FiDelete />} onClick={clearExpression.bind(this)} size="xs" rounded="md" />}
          />
        </InputGroup>

        <InputGroup w="37%">
          <Input placeholder="Context" defaultValue={context} onChange={handleContext.bind(this)} bg="white.100" rounded="md" />
          <InputRightElement
            children={<IconButton variant="outline" tabIndex="-1" icon={<FiDelete />} onClick={clearContext.bind(this)} size="xs" rounded="md" />}
          />
        </InputGroup>

        <InputGroup w="100%">
          <Input placeholder="Definition" defaultValue={definition} onChange={handleDefinition.bind(this)} bg="white.100" rounded="md" />
          <InputRightElement
            children={
              <IconButton
                userSelect="none"
                variant="outline"
                tabIndex="-1"
                icon={<FiDelete />}
                onClick={clearDefinition.bind(this)}
                size="xs"
                rounded="md"
              />
            }
          />
        </InputGroup>

        <Button onClick={addEntry.bind(this)} colorScheme="blue" isDisabled={isDisabled}>
          Save
        </Button>
      </HStack>
    </>
  );
};

export default AddNewEntry;
